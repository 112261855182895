import React from 'react'

function Checkbox({state}) {

  // console.log(state)
  

  return (
    <span className={`sbCheck ${state ? 'checked' : ''}`}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path className={`box`} d="M82,89H18c-3.87,0-7-3.13-7-7V18c0-3.87,3.13-7,7-7h64c3.87,0,7,3.13,7,7v64C89,85.87,85.87,89,82,89z"/>
          <polyline className={`check`} points="25.5,53.5 39.5,67.5 72.5,34.5 "/>
      </svg>
    </span>
  )
}
export default Checkbox