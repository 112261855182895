import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { useQueryParamString } from 'react-use-query-param-string'

import Checkbox from 'src/images/socialIcons/checkbox'

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'


const Filters = ({ resetFilterLabel, resetFilterActionLabel, orders, filtersOpen, attributesMenuLabel, archiveNavMode,pageCounts, pageLoad, location }) => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const filters = archiveNavMode ? (    
    [...state.catFilters.type || [], ...state.catFilters.purpose || [], ...state.catFilters.category || [] ]
    ) 
    : state.catFilters.common.length >= 0 ? state.catFilters.common : []


  const filterLabels = Object.keys(orders) 
  const filterLabelsFiltredIds = []  
  const filterLabelsFiltred = {}  
  filters.forEach(
    it => {
      if(filterLabels.includes(it.parent) && !filterLabelsFiltredIds.includes(it.parent) && it.count < pageCounts) {
        filterLabelsFiltredIds.push(it.parent)
        filterLabelsFiltred[it.parent] = it.parentName        
      }
    }    
  )



  const [filtersUrl, setFiltersUrl, initialized] = useQueryParamString('fil', '')
  const [typeUrl, setTypeUrl, typeInitialized] = useQueryParamString('typ', '')
  const [purpUrl, setPurpUrl, purpInitialized] = useQueryParamString('pur', '')
  const [catUrl, setCatUrl, catInitialized] = useQueryParamString('cat', '')
  // console.log(searchText)
  // console.log(initialized)


  function handleClick(attr) {
    if(state.activeFilters.length === 0) {
      dispatch( { type: 'ACTIVE_FILTERS', value: [attr.id] } )
      setFiltersUrl(attr.id)    
    } else {      
      if(state.activeFilters.includes(attr.id)) {
        const temp = state.activeFilters.filter((item) => {
          return item !== attr.id
        })
        dispatch( { type: 'ACTIVE_FILTERS', value: [...temp] } )
        setFiltersUrl(temp.join())        
      } else {
        dispatch( { type: 'ACTIVE_FILTERS', value: [...state.activeFilters, attr.id] } )
        setFiltersUrl([...state.activeFilters, attr.id].join())
      }
    }    
  }
  function handleClickType(attr) {
    if(state.activeTypeFilters.length === 0) {
      dispatch( { type: 'ACTIVE_TYPE_FILTERS', value: [attr.id] } )
      setTypeUrl(attr.id)
    } else { 
      if(state.activeTypeFilters.includes(attr.id)) {
        const temp = state.activeTypeFilters.filter((item) => {
          return item !== attr.id
        })
        dispatch( { type: 'ACTIVE_TYPE_FILTERS', value: [...temp] } )
        setTypeUrl(temp.join())
      } else {
        dispatch( { type: 'ACTIVE_TYPE_FILTERS', value: [...state.activeTypeFilters, attr.id] } )          
        setTypeUrl([...state.activeTypeFilters, attr.id].join())
      }
    }
  }
  function handleClickPurpose(attr) {
    if(state.activePurposeFilters.length === 0) {
      dispatch( { type: 'ACTIVE_PURPOSE_FILTERS', value: [attr.id] } )         
      setPurpUrl(attr.id)
    } else {      
      if(state.activePurposeFilters.includes(attr.id)) {
        const temp = state.activePurposeFilters.filter((item) => {
          return item !== attr.id
        })
        dispatch( { type: 'ACTIVE_PURPOSE_FILTERS', value: [...temp] } )   
        setPurpUrl(temp.join())     
      } else {
        dispatch( { type: 'ACTIVE_PURPOSE_FILTERS', value: [...state.activePurposeFilters, attr.id] } )   
        setPurpUrl([...state.activePurposeFilters, attr.id].join())     
      }
    }
  }
  function handleClickCategory(attr) {
    if(state.activeCategoryFilters.length === 0) {
      dispatch( { type: 'ACTIVE_CATEGORY_FILTERS', value: [attr.id] } )
      setCatUrl(attr.id)
    } else {      
      if(state.activeCategoryFilters.includes(attr.id)) {
        const temp = state.activeCategoryFilters.filter((item) => {
          return item !== attr.id
        })
        dispatch( { type: 'ACTIVE_CATEGORY_FILTERS', value: [...temp] } )   
        setCatUrl(temp.join())     
      } else {
        dispatch( { type: 'ACTIVE_CATEGORY_FILTERS', value: [...state.activeCategoryFilters, attr.id] } )
        setCatUrl([...state.activeCategoryFilters, attr.id].join())
      }
    }
  }
  const resetFilters = () => {

    dispatch( { type: 'ACTIVE_FILTERS', value: [] } )

    setFiltersUrl('')

  }


  // CALLBACK FUNCTION EXTRACTING FILTER PARAMS FROM QUERY STRING
  const extractParams = ( ind, qString ) => {
    const temp = qString.substring( ind + 4 )  
    let temp2 = ''
    if( temp.indexOf('&') !== -1 ) {
      temp2 = temp.substring( 0, temp.indexOf('&') ).split('%2C')
    } else {
      temp2 = temp.split('%2C')
    }
    return temp2
  }

  /*****************************  RESET FILTERS ON PAGE LOAD (VARIANTS)  *****************************/
  useEffect(() => {

    let tempCommon = []
    let tempType = []
    let tempPurpose = []
    let tempCat = []

    if( typeof location.search === 'string' && location.search.length > 0 ) {

      const testCommon = location.search.indexOf('fil=')
      if( testCommon !== -1 ) {
        const temp = extractParams( testCommon, location.search )
        tempCommon = [...temp]
      }

      const testType = location.search.indexOf('typ=')
      if( testType !== -1 ) {
        const temp = extractParams( testType, location.search )
        tempType = [...temp]
      }

      const testPurpose = location.search.indexOf('pur=')
      if( testPurpose !== -1 ) {
        const temp = extractParams( testPurpose, location.search )
        tempPurpose = [...temp]
      }

      const testCat = location.search.indexOf('cat=')
      if( testCat !== -1 ) {
        const temp = extractParams( testCat, location.search )
        tempCat = [...temp]
      }

    }
    


    // let searchFilters = []    
    // if( location ) {
    //   if( location.search.includes('filters=') ) {  
    //     const temp = (typeof location.search.substring( location.search.indexOf('filters=') + 8 ) === 'string' && location.search.substring( location.search.indexOf('filters=') + 8 ).length > 0) ?
    //      location.search.substring( location.search.indexOf('filters=') + 8 ).split(",")
    //      : []  
    //     if(temp.length > 0) {
    //       searchFilters = [...temp]
    //     }  
    //   }
    // }
    // // dispatch( { type: 'ACTIVE_FILTERS', value: searchFilters } )




    //////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////
    //     PERSIST FILTERS ON PAGE LOAD, IF NEW ITEM LIST CONTAINS USED FILTER VALUES    
    // const tempCommon = state.catFilters.common.filter(el => {
    //   return state.activeFilters.includes(el.id)
    // })
    // const tempType = state.catFilters.type.filter(el => {
    //   return state.activeTypeFilters.includes(el.id)
    // })
    // const tempPurpose = state.catFilters.purpose.filter(el => {
    //   return state.activePurposeFilters.includes(el.id)
    // })
    // const tempCat = state.catFilters.category.filter(el => {
    //   return state.activeCategoryFilters.includes(el.id)
    // })
    // dispatch( { type: 'ACTIVE_FILTERS', value: tempCommon.map(el => el.id) } )
    // dispatch( { type: 'ACTIVE_TYPE_FILTERS', value: tempType.map(el => el.id) } )
    // dispatch( { type: 'ACTIVE_PURPOSE_FILTERS', value: tempPurpose.map(el => el.id) } )
    // dispatch( { type: 'ACTIVE_CATEGORY_FILTERS', value: tempCat.map(el => el.id) } )
    //
    //
    //      XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //
    //              RESET ALL FILTERS ON EVERY PAGE LOAD    
    // dispatch( { type: 'ACTIVE_FILTERS', value: [] } )
    dispatch( { type: 'ACTIVE_FILTERS', value: [...tempCommon] } )
    dispatch( { type: 'ACTIVE_TYPE_FILTERS', value: [...tempType] } )
    dispatch( { type: 'ACTIVE_PURPOSE_FILTERS', value: [...tempPurpose] } )
    dispatch( { type: 'ACTIVE_CATEGORY_FILTERS', value: [...tempCat] } )
    //
    //////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

  }, [pageLoad])
  // }, [location])
  // }, [location.href])


  const initFilters = () => {
    const x = {}
    Object.values(orders).forEach(el => {
      x[el] = true
    });
    return x
  }
  const[openFilters, setOpenFilters] = useState(archiveNavMode ? {} : () => initFilters())



  const[cssDelay, setcssDelay] = useState(false)
  useLayoutEffect(() => {
    setcssDelay(true)
    const timer = setTimeout(() => {
      setcssDelay(false)
    }, 222);
    return () => clearTimeout(timer);
  }, [filtersOpen])
  
  const[cssDelayRes, setcssDelayRes] = useState(false)
  useLayoutEffect(() => {
    state.activeFilters.length === 0 &&
    setcssDelayRes(true)
    const timer = setTimeout(() => {
      setcssDelayRes(false)
    }, 444);
    return () => clearTimeout(timer);
  }, [state.activeFilters])

  
  return (
    <div
      className={`filtersContainer ${cssDelay ? 'transition' : ''}`}
      style={
        pageLoad === true ?
        {
          height: '0',
          transition: 'all 1ms !important'
        } :
        archiveNavMode ?
        {
          height: 'auto'
        } :
        filtersOpen ?
        {
          height: `${cssDelay ? `${(filterLabelsFiltredIds.length + 2) * 36}px` : 'auto'}`,
        }
        :
        {
          height: `${cssDelay ? `${(filterLabelsFiltredIds.length + 2) * 36}px` : '0px'}`,
        }
      }
    >
      {/* {state.activeFilters.length > 0 ? */}
        {!archiveNavMode && 
          <button
            className={`filterMenuButton sidebarButton ${cssDelayRes ? 'transition' : 'active'}`}

            // onClick={() => {dispatch( { type: 'ACTIVE_FILTERS', value: [] } )}}
            // onKeyDown={() => {dispatch( { type: 'ACTIVE_FILTERS', value: [] } )}}

            onClick={resetFilters}
            onKeyDown={resetFilters}

            tabIndex="0"
            style={
              ( !( state.activeFilters.length > 0 ) && !cssDelayRes ) ? 
              {
                opacity: '0',
                cursor: 'initial',
                pointerEvents: 'none'
              } :
              {
                opacity: '1'
              }
            }
          >
            {(!cssDelayRes && state.activeFilters.length > 0) ? resetFilterLabel : resetFilterActionLabel}
          </button>
        }
      {/* :
        <div style={{height:'52px'}}></div>
      } */}


      {/* {attributesMenuLabel && 
        <h5
          style={{
            fontFamily: 'Montserrat',
            textTransform: 'lowercase',
            letterSpacing:'initial',
            fontWeight: '300'
          }}
        >
          {attributesMenuLabel}
        </h5>
      } */}

      
      <ul 
        id={`filterMenu`}
        className={`filterMenu ${cssDelay ? 'transition' : ''}`}
      >

        {(!archiveNavMode && filterLabelsFiltredIds.length > 0) && filterLabelsFiltredIds.map((lab, key) => {    
          const mark = orders[lab]

            return(
              <li 
                key={key}
                className={`filterMenuItem filterMenuItemLabel order-${typeof orders[lab] !== 'undefined' ? orders[lab] : ''}-label`}
                onClick={archiveNavMode ? () => {} : () => setOpenFilters( prevState => ( openFilters[mark] === false ? {...prevState, [mark] : true } : {...prevState, [mark] : false } ) )}
                onKeyDown={archiveNavMode ? () => {} : () => setOpenFilters( prevState => ( openFilters[mark] === false ? {...prevState, [mark] : true } : {...prevState, [mark] : false } ) )}
                tabIndex="0"
              >
                <span className={`${lab}`}>
                  {filterLabelsFiltred[lab]}
                </span>

                {!archiveNavMode &&
                  <React.Fragment>
                    {openFilters[mark] === false ? 
                    <span 
                      style={{
                        fontSize:'14px',
                        paddingRight: '10px',
                        color: '#f3cf45'
                      }}
                      >▲</span> 
                      : 
                      <span 
                      style={{
                        fontSize:'14px',
                        paddingRight: '10px',
                        color: 'white'
                      }}
                    >▼</span>}
                  </React.Fragment>
                }
              </li>
            )

        })}

        {filterLabelsFiltredIds.length > 0 && filterLabelsFiltredIds.map((lab, key) => {    
          return(
            <li 
              key={key}
              className={`filterMenuItem filterMenuSeparator order-${typeof orders[lab] !== 'undefined' ? orders[lab] : ''}-separator`}
              style={
                openFilters[orders[lab]] ? 
                {
                  height:'0px',
                  borderTop:"1px solid #F3CF45",
                  opacity: '0.3'
                } :
                {
                  height:'15px',
                  borderTop:"1px solid #F3CF45",
                  opacity: '0.3'
                }
              }
            >
            </li>
          )
        })}
        {filters.length > 0 && filters.map((attr, key) => {
          // console.log(state.filtred[attr.id])
          // console.log(state.countByFilter[attr.section][attr.id].count)
          return(
            <React.Fragment key={key}>
            {(attr.count < pageCounts && typeof filterLabelsFiltred[attr.parent] !== 'undefined') && <li 
              className={`filterMenuItem ${attr.id} ${cssDelay ? 'transition' : ''} order-${typeof orders[attr.parent] !== 'undefined' ? orders[attr.parent] : ''} posRelative ${( 
                !state.activeFilters.includes(attr.id) 
                && !state.activeTypeFilters.includes(attr.id) 
                && !state.activePurposeFilters.includes(attr.id) 
                && !state.activeCategoryFilters.includes(attr.id) 
                ) ? '' : 'active'}`}

              style={
                openFilters[orders[attr.parent]] ? 
                {
                  height:'0px',
                  marginBottom:'0'
                } : 
                ( 
                  !state.activeFilters.includes(attr.id) 
                  && !state.activeTypeFilters.includes(attr.id) 
                  && !state.activePurposeFilters.includes(attr.id) 
                  && !state.activeCategoryFilters.includes(attr.id) 
                ) ?
                {
                  height:'auto',
                  marginBottom:'8px'
                } :
                {
                  height:'auto',  
                  marginBottom:'8px',
                }
              }
              
            >
              <Checkbox 
                state={
                  attr.section === 'type' ? state.activeTypeFilters.includes(attr.id) : 
                  attr.section === 'purpose' ? state.activePurposeFilters.includes(attr.id) : 
                  attr.section === 'category' ? state.activeCategoryFilters.includes(attr.id) : 
                  state.activeFilters.includes(attr.id)
                } 
              />
              <button
                className={`filtersButton`}
                onClick={() => {
                  if(attr.section === 'type') {
                    handleClickType(attr)
                  }
                  else if(attr.section === 'purpose') {
                    handleClickPurpose(attr)
                  }
                  else if(attr.section === 'category') {
                    handleClickCategory(attr)
                  }
                  else{
                    handleClick(attr)
                  }
                }}
                onKeyDown={() => {
                  if(attr.section === 'type') {
                    handleClickType(attr)
                  }
                  else if(attr.section === 'purpose') {
                    handleClickPurpose(attr)
                  }
                  else if(attr.section === 'category') {
                    handleClickCategory(attr)
                  }
                  else{
                    handleClick(attr)
                  }
                }}
                tabIndex="0"
              
              >
                {attr.name}
                <span className={`filterMenuItemCount`}>( {attr.count} )</span>
              </button>
            </li>}
            </React.Fragment>
          )
        })}
      </ul>

    </div>
  )

}
export default Filters